<template>
  <div class="pay">
    <van-nav-bar title="支付" left-arrow @click-left="onClickLeft" />
    <main>
      <div class="heng"></div>
      <div class="content">
        <p class="title">继续使用请购买</p>
        <div class="line"></div>
        <van-radio-group
          class="radiomain"
          v-model="radio"
          @change="rediochange"
        >
          <van-radio
            v-for="(item, index) in payproject"
            :key="index"
            :name="item.id"
            @click="getmoney(item)"
            >ཉིན་{{ item.date }} སྒོར་{{ item.money }}</van-radio
          >
        </van-radio-group>
      </div>
      <div class="btn" v-if="isShow" @click="companyPay">微信支付</div>
    </main>
  </div>
</template>

<script>
import { wxPay, vip, payagent } from "@/api";
import { debounce } from '@/components/debounce.js'
import { Dialog, Toast } from 'vant'
export default {
  data() {
    return {
      radio: "",
      isShow: false,
      payproject: [],
      money: "",
      ip: "",
    };
  },
  created() {
    this.ip = this.$route.query.ip;
    payagent().then((res) => {
      this.payproject = res.data;
    });
  },
  methods: {
    companyPay() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      })
      console.log(1)
      let params = {
        projectId: this.radio,
      }; //根据后端所需传参数
      wxPay(params).then((res) => {
        if (res.data) {
          Toast.clear()
          this.weChatParameter = res.data;
          this.weixinPay();
        } else {
          Toast.clear()
          Dialog.alert({
            title: '提示',
            message: '您当前已是会员,请勿重复支付~'
          })
          if (this.ip == 1) {
            this.$router.push({
              path: "/examination",
              query: {
                id: 3,
              },
            });
          } else {
            this.$router.push({
              path: "/exercise",
              query: {
                id: 3,
              },
            });
          }
        }
      })
    },
    getmoney(e) {
      this.money = e.money;
    },
    onClickLeft() {
      if (this.ip == 1) {
        this.$router.push({
          path: "/examination",
          query: {
            id: 3,
          },
        });
      } else {
        this.$router.push({
          path: "/exercise",
          query: {
            id: 3,
          },
        });
      }
    },
    rediochange(e) {
      if (this.radio) {
        this.isShow = true;
      }
    },

    //微信内置浏览器类，weChatParameter对象中的参数是3.步骤代码中从后端获取的数据
    onBridgeReady() {
      var that = this;
      // const timestamp = parseInt(that.weChatParameter.timeStamp)
      var timestamp = Math.round(that.weChatParameter.timeStamp).toString();
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          debug: false,
          appId: that.weChatParameter.appId, //公众号名称，由商户传入
          timeStamp: timestamp, //时间戳，自1970年以来的秒数
          nonceStr: that.weChatParameter.nonceStr, //随机串
          package: that.weChatParameter.packageStr,
          signType: that.weChatParameter.signType, //微信签名方式：
          paySign: that.weChatParameter.paySign, //微信签名
          jsApiList: ["chooseWXPay"],
        },
        function (res) {
          // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            //支付成功后的操作
            that.$router.replace({
              path: "/paysucess",
              query: {
                id: that.money,
                ip: that.ip,
              },
            });
            vip().then((res) => {
              localStorage.setItem("vip", res.data);
            });
          } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
            //取消支付的操作
            that.$toast({
              message: "取消支付",
              duration: 2000,
            });
          } else {
            //支付失败的操作
            that.$toast({
              message: "‘支付失败’",
              duration: 2000,
            });
          }
        }
      );
    },
    //解决微信内置对象报错
    weixinPay(params) {
      var that = this;
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            that.onBridgeReady(params),
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent(
            "WeixinJSBridgeReady",
            that.onBridgeReady(params)
          );
          document.attachEvent(
            "onWeixinJSBridgeReady",
            that.onBridgeReady(params)
          );
        }
      } else {
        that.onBridgeReady();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.pay {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  main {
    flex: 1;
    .heng {
      width: 310px;
      height: 16px;
      background: #dce4e2;
      border-radius: 23px;
      margin: 47px auto 0;
    }
    .content {
      width: 297px;
      background: #ffffff;
      box-shadow: 0px 6px 13px 0px rgba(211, 211, 211, 0.5);
      border-radius: 0 0 6px 6px;
      margin: -8px auto 0;
      padding-bottom: 10px;
      .title {
        text-align: center;
        padding-top: 21px;
        font-size: 20px;
        font-family: SourceHanSansSC-Medium, SourceHanSansSC;
        font-weight: 500;
        color: #171717;
        line-height: 29px;
      }
      .line {
        margin-top: 12px;
        width: 295px;
        height: 1px;
        border-bottom: 1px dashed #cccccc;
      }
      .radiomain {
        width: 169px;
        margin: 26px 0 0 63px;
        .van-radio {
          margin-bottom: 16px;
          .van-radio__label {
            font-size: 18px;
            font-family: SourceHanSansSC-Regular, SourceHanSansSC;
            font-weight: 400;
            color: #171717;
            line-height: 18px;
          }
        }
      }
    }
    .btn {
      width: 240px;
      height: 36px;
      background: linear-gradient(180deg, #37a9ff 0%, #0067fe 100%);
      border-radius: 18px;
      margin: 32px auto 0;
      font-size: 18px;
      font-family: SourceHanSansSC-Medium, SourceHanSansSC;
      font-weight: 500;
      color: #fcfbfb;
      line-height: 36px;
      text-align: center;
    }
  }
}
</style>
